import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
// import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import Button from 'react-bootstrap/Button';
import {Link} from 'gatsby';
const navBar = () => {
  return (
  <Navbar expand="md" className="container-fluid border-bottom rounded shadow">
    <Navbar.Brand href="#home" >OAS Atlas of Competitive Intelligence</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className='ml-auto' >
        <Link to="/home/" className="nav-link">Home</Link>
        <Link to="/publications/" className="nav-link">Publications</Link>
        <NavDropdown title="Research" id="basic-nav-dropdown-res">
          <Link to="/research/" className="dropdown-item">Research</Link>
        </NavDropdown>
        <NavDropdown title="Language" id="basic-nav-dropdown-lang">
          <NavDropdown.Item href="#en">English</NavDropdown.Item>
          <NavDropdown.Item href="#es">Spanish</NavDropdown.Item>
          <NavDropdown.Item href="#fr">Français</NavDropdown.Item>
          <NavDropdown.Item href="#pt">Português</NavDropdown.Item>
        </NavDropdown>
      </Nav>
      {/*
        <Form inline>
     <FormControl type="text" placeholder="Search" className="mr-sm-2" />
     <Button variant="outline" >Search</Button>
   </Form>
   */}
 </Navbar.Collapse>
  </Navbar>)
}

export default navBar;

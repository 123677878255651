import React from 'react'
import Navbar from '../Navbar/Navbar'
import Aux from "../../hoc/Aux"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../../../css/theme.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

const layout = (props) => (
  <Aux>
    <Container fluid bg="dark">
      <Row className="mb-4">
        <Navbar/>
      </Row>
      <Row className="justify-content-center">
        <Col md={9} >{props.children}</Col>
      </Row>
    </Container>
  </Aux>
)



export default layout
